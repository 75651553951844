export default [
  // {
  //   url: "/#home",
  //   name: "Home",
  //   link: "home",
  // },
  {
    url: "/#about",
    name: "About",
    link: "about",
  },
  {
    url: "/#whyTrolet",
    name: "Why Trolet Inn",
    link: "whyTrolet",
  },
  {
    url: "/#famous",
    name: "Famous Aroun Ujjain",
    link: "famous",
  },
  {
    url: "/#gallery",
    name: "Gallery",
    link: "gallery",
  },
  {
    url: "https://www.airbnb.co.in/rooms/7626649?source_impression_id=p3_1693733910_fJjDzR6F5%2F6A4kro&modal=PHOTO_TOUR_SCROLLABLE",
    name: "Book Now",
    link: "bookNow",
  },
  /*{
    url: "/#trustedBy",
    name: "Trusted By",
    link: "trustedBy",
  },*/
  {
    url: "/#contactUs",
    name: "Get In Touch",
    link: "contactUs",
  },
  {
    url: "/blog",
    name: "Blog",
    link: "blog",
  }
]

export const BOOK_NOW_URL = 'https://www.airbnb.co.in/rooms/7626649?source_impression_id=p3_1693733910_fJjDzR6F5%2F6A4kro&modal=PHOTO_TOUR_SCROLLABLE'