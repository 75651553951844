import React from "react"
import ReactNotification from "react-notifications-component"
import { FaFacebook, FaInstagram, FaAirbnb } from "react-icons/fa"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import "react-notifications-component/dist/theme.css"

const Footer = ({ brandWhite }) => {
  return (
    <footer className="footer">
      <ReactNotification />
      <div className="container">
        <div className="footer-bottom">
          <div className="row align-items-center">
            <div className="col-md-4 col-sm-12">
              <p>© 2020 TroLet Inn</p>
            </div>
            <div className="col-md-4 col-sm-12 text-center">
              <Link to="/#home">
                <img src={brandWhite} alt="brand" className="brand" />
              </Link>
            </div>
            <div className="col-md-4 col-sm-12 text-right">
              <div className="social-links">
                <a
                  href="https://www.facebook.com/trolet64/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://www.airbnb.co.in/rooms/7626649"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaAirbnb />
                </a>
              </div>
            </div>
          </div>
          <div className="wave-wrapper">
            <div className="wave"></div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  brandWhite: PropTypes.string
}

export default Footer
