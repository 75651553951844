import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import $ from "jquery/dist/jquery.slim"

import Links from "../constant/Links"

const Header = ({ title, brand }) => {
  const [activeClass, setActiveClass] = useState(null)
  const [url, setUrl] = useState(null)
  const [isOpen, toggleNavBar] = useState(null)

  if (typeof window !== `undefined`) {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop()

      if (scroll >= 100) {
        $(".nav-main").addClass("nav-display")
      } else {
        $(".nav-main").removeClass("nav-display")
      }
    })
  }

  const showClass = data => {
    setActiveClass("nav-item active")
    setUrl(data)
    return true
  }

  const isNavOpen = () => {
    toggleNavBar(isOpen => !isOpen)
  }
  return (
    <nav className="navbar navbar-expand-xl navbar-light nav-main">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img src={brand} alt="brand" className="img-fluid brand" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target=".navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => isNavOpen()}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={isOpen ? "show-nav" : "collapse navbar-collapse"}
          id="#navbar"
        >
          <ul className="navbar-nav ml-auto mr-0">
            {Links.map((linkData, index) => (
              <li
                key={index}
                className={url === linkData.link ? activeClass : "nav-item"}
              >
                <Link
                  className="nav-link"
                  to={linkData.url}
                  onClick={() => showClass(linkData.link)}
                >
                  {linkData.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  brand: PropTypes.string,
}

export default Header
